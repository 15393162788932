import React, { Component } from 'react';
import classNames from 'classnames';
import './prevention-units-overview.scss';
import clone from 'ramda/src/clone';
import DonutChart from '../../../common/widget/DonutChart';
import Legend, { TLegendItem } from '../../../common/widget/Legend';
import Translate from '../../../common/Translate';
import {
    PREVENTION_UNITS_CONFIG,
    PREVENTION_UNITS_CONFIG_FOR_OVERVIEW_LEGEND,
} from '../../../../config/administration.config';
import TooltipWithIcon from '../../../common/widget/TooltipWithIcon';
import Icon from '../../../common/icons/Icon';
import MasterWithDetail from '../../../common/widget/MasterWithDetail';
import ROUTE_KEYS from '../../../../routeKeys';
import {
    IRenderMasterContentProps,
    IRenderSearchContentProps,
    ITransformToActiveFiltersProps,
    IRenderDetailHeaderProps,
    IRenderDetailContentProps,
    IRenderFilterContentProps,
    IClientSideFilterOfListDataProps,
    IFetchAllExportDataProps,
} from '../../../common/widget/MasterWithDetail/typings';
import { ListItem, ListColumns, ISortedColumn, SortOrder, SortType } from '../../../../models/general/list';
import {
    PreventionUnitId,
    IPreventionUnitConfigItem,
    IPreventionUnits,
    IPreventionUnitNumbers,
    IPreventionUnitTask,
    TPreventionUnitsGroupType,
} from '../../../../models/admin/preventionUnits';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../common/input/TextInput';
import { createGenericActiveFilters } from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import {
    getPreventionUnits,
    getPreventionUnitsAsyncInfo,
    getPreventionUnitsDetailAsyncInfo,
    getPreventionUnitsDetail,
} from '../../../../redux/preventionUnits/selectors';
import { formatFloat } from '../../../../utils/formatting/formatFloat';
import { Detail, Header } from './detail';
import { CLASS_NAME } from './common';
import { TChartData, ChartDataItem } from 'react-minimal-pie-chart';
import List from '../../../common/list/List';
import sortListItems from '../../../../utils/list/sortListItems';
import { ITranslator } from '../../../../models/general/i18n';
import TranslatorContext from '../../../appShell/contexts/TranslatorContext';
import { sumFloatsUpToSixDigitsAfterComma } from '../../../../utils/core/number/calculations';
import getUniqueTypeaheadFilterValuesFromListItems
    from '../../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import CheckboxesOrTypeaheadFilter from '../../../common/input/CheckboxesOrTypeaheadFilter';
import { separateStringList } from '../../../../utils/core/string/separatedStringList';
import { exportPreventionUnits } from '../../../../api/admin/preventionUnits.api';

const BASE_NAME = 'prevention-units-overview';
const TRANSLATION_PREFIX = 'administration.prevention_units.overview';
const LIST_ITEM_ID_TOTAL = 'PE-total';

interface IPublicProps {
    routeKey: ROUTE_KEYS;
    detailRouteKey: ROUTE_KEYS;
    totalPreventionUnits: IPreventionUnitNumbers;
    preventionUnitsGroupType: TPreventionUnitsGroupType;
    companyCode: string;
    overviewYear: number;
}

interface IContextProps {
    translator: ITranslator;
}

type TOverviewListProps = IRenderMasterContentProps<ListItem<IColumnNames>[]> & IContextProps;

interface IFilterValues {
    search: string;
    type: string;
}

interface IColumnNames {
    type: string;
    [PreventionUnitId.Assigned]: string;
    [PreventionUnitId.Planned]: string;
    [PreventionUnitId.Registered]: string;
    [PreventionUnitId.Invoiced]: string;
    [PreventionUnitId.Remaining]: string;
}

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'type',
    sortOrder: SortOrder.Ascending,
};

const COLUMNS: ListColumns<IColumnNames> = {
    type: {
        label: null, // Dynamically set in component render
        sortType: SortType.String,
        percentWidth: 30,
    },
    assigned: {
        headerRender: () => createPreventionUnitLabel(
            PREVENTION_UNITS_CONFIG.find((config) => config.id === PreventionUnitId.Assigned),
            true,
        ),
        percentWidth: 14,
        selectable: true,
        label: <Translate msg="administration.prevention_units.types.assigned.label" />,
        render: (listItem: ListItem<IColumnNames>) =>
            formatFloat(listItem.columns[PreventionUnitId.Assigned] as number, 4, true),
        align: 'right',
    },
    planned: {
        headerRender: () => createPreventionUnitLabel(
            PREVENTION_UNITS_CONFIG.find((config) => config.id === PreventionUnitId.Planned),
            true,
        ),
        percentWidth: 14,
        selectable: true,
        label: <Translate msg="administration.prevention_units.types.planned.label" />,
        render: (listItem: ListItem<IColumnNames>) =>
            formatFloat(listItem.columns[PreventionUnitId.Planned] as number, 4, true),
        align: 'right',
    },
    registered: {
        headerRender: () => createPreventionUnitLabel(
            PREVENTION_UNITS_CONFIG.find((config) => config.id === PreventionUnitId.Registered),
            true,
        ),
        percentWidth: 14,
        selectable: true,
        label: <Translate msg="administration.prevention_units.types.registered.label" />,
        render: (listItem: ListItem<IColumnNames>) =>
            formatFloat(listItem.columns[PreventionUnitId.Registered] as number, 4, true),
        align: 'right',
    },
    invoiced: {
        headerRender: () => createPreventionUnitLabel(
            PREVENTION_UNITS_CONFIG.find((config) => config.id === PreventionUnitId.Invoiced),
            true,
        ),
        percentWidth: 14,
        selectable: true,
        label: <Translate msg="administration.prevention_units.types.invoiced.label" />,
        render: (listItem: ListItem<IColumnNames>) =>
            formatFloat(listItem.columns[PreventionUnitId.Invoiced] as number, 4, true),
        align: 'right',
    },
    remaining: {
        headerRender: () => createPreventionUnitLabel(
            PREVENTION_UNITS_CONFIG.find((config) => config.id === PreventionUnitId.Remaining),
            true,
        ),
        percentWidth: 14,
        label: <Translate msg="administration.prevention_units.types.remaining.label" />,
        render: (listItem: ListItem<IColumnNames>) =>
            formatFloat(listItem.columns[PreventionUnitId.Remaining] as number, 4, true),
        align: 'right',
    },
};

export default class Overview extends Component<IPublicProps> {
    constructor(props: IPublicProps) {
        super(props);

        this.renderDonutChartLabel = this.renderDonutChartLabel.bind(this);
        this.createLegendData = this.createLegendData.bind(this);
        this.createChartData = this.createChartData.bind(this);

        this.transformFilterValuesToActiveFilters = this.transformFilterValuesToActiveFilters.bind(this);
        this.doArchiveExportOfLatestYear = this.doArchiveExportOfLatestYear.bind(this);
    }

    public render() {
        const { totalPreventionUnits, routeKey, detailRouteKey } = this.props;

        return (
            <div className={CLASS_NAME}>
                {totalPreventionUnits && (
                    <div className={`${CLASS_NAME}__statistics`}>
                        <div className={`${CLASS_NAME}__graph`}>
                            <DonutChart
                                className={`${CLASS_NAME}__donut-chart`}
                                data={this.createChartData()}
                                lineWidth={35}
                                label={this.renderDonutChartLabel()}
                            />
                            <Legend
                                className={`${CLASS_NAME}__legend`}
                                titleTranslationKey={`${TRANSLATION_PREFIX}.stats.legend.title`}
                                titlePlaceholders={{
                                    total: formatFloat(totalPreventionUnits.assigned, 4, true),
                                }}
                                data={this.createLegendData()}
                                tooltipTranslationKey="administration.prevention_units.types.assigned.tooltip"
                            />
                        </div>
                        <div className={`${CLASS_NAME}__info`}>
                            <Translate
                                msg={`${TRANSLATION_PREFIX}.stats.info.used`}
                                placeholders={{
                                    total: formatFloat(totalPreventionUnits.assigned, 4, true),
                                    totalUsed: formatFloat(
                                        sumFloatsUpToSixDigitsAfterComma(
                                            [
                                                totalPreventionUnits.invoiced,
                                                totalPreventionUnits.registered,
                                            ]),
                                        4,
                                        true,
                                    ),
                                    usedPaid: formatFloat(totalPreventionUnits.invoiced, 4, true),
                                    usedUnpaid: formatFloat(totalPreventionUnits.registered, 4, true),
                                }}
                                raw={true}
                            />
                            <Translate
                                msg={`${TRANSLATION_PREFIX}.stats.info.planned`}
                                placeholders={{
                                    planned: formatFloat(totalPreventionUnits.planned, 4, true),
                                }}
                                raw={true}
                            />
                            <Translate
                                msg={`${TRANSLATION_PREFIX}.stats.info.remaining`}
                                placeholders={{
                                    remaining: formatFloat(totalPreventionUnits.remaining, 4, true),
                                }}
                                raw={true}
                            />
                        </div>
                    </div>
                )}
                <div className={`${CLASS_NAME}__list`}>
                    <h2><Translate msg={`${TRANSLATION_PREFIX}.list.title`} /></h2>
                    <MasterWithDetail
                        baseName={BASE_NAME}
                        masterConfig={{
                            routeKey,
                            asyncInfoSelector: getPreventionUnitsAsyncInfo,
                            dataSelector: getPreventionUnits,
                            transformData: mapPreventionUnitsToListItems,
                            renderContent: (renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[]>) =>
                                <OverviewList {...renderProps} {...this.props} />,
                            clientSideSearchOfListData: {
                                searchFilterName: 'search',
                                columnsConfig: COLUMNS,
                                acceptCommasAndPointsAsDecimalSeparator: true,
                            },
                            clientSideFilterOfListData,
                            transformFilterValuesToActiveFilters: this.transformFilterValuesToActiveFilters,
                        }}
                        headerConfig={{
                            renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                                <SearchContent {...renderProps} />,
                            renderFilterContent:
                                (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                                    <FilterContent {...renderProps} {...this.props} />,
                            exportButton: {
                                baseFilename: 'pe-overview',
                                fetchAllExportData: {
                                    isFetchNeeded: this.isFetchArchiveExportNeeded,
                                    apiCall: this.doArchiveExportOfLatestYear,
                                    willResultAlreadyBeAFile: true,
                                },
                            },
                        }}
                        detailConfig={{
                            routeKey: detailRouteKey,
                            asyncInfoSelector: getPreventionUnitsDetailAsyncInfo,
                            dataSelector: getPreventionUnitsDetail,
                            idRouteParamName: 'type',
                            renderHeader: (renderProps: IRenderDetailHeaderProps<IPreventionUnitTask[]>) =>
                                <Header {...renderProps} />,
                            renderContent: (renderProps: IRenderDetailContentProps<IPreventionUnitTask[]>) =>
                                <Detail {...renderProps} />,
                        }}
                    />
                </div>
            </div>
        );
    }

    private createChartData(): TChartData {
        const { totalPreventionUnits } = this.props;

        return PREVENTION_UNITS_CONFIG_FOR_OVERVIEW_LEGEND
            .map((config) => {
                return {
                    color: config.color,
                    value: totalPreventionUnits[config.id],
                } as ChartDataItem;
            });
    }

    private createLegendData(): TLegendItem[] {
        const { totalPreventionUnits } = this.props;

        return PREVENTION_UNITS_CONFIG_FOR_OVERVIEW_LEGEND
            .map((config) => {
                const item: TLegendItem = {
                    color: config.color,
                    valueColorOverride: config.legendColorOverride,
                    label: createPreventionUnitLabel(config),
                    value: formatFloat(totalPreventionUnits[config.id], 4, true),
                };
                return item;
            });
    }

    private renderDonutChartLabel() {
        const { totalPreventionUnits } = this.props;

        return (
            <div className={`${CLASS_NAME}__donut-chart-label`}>
                <span className="amount">{formatFloat(totalPreventionUnits.assigned, 4, true)}</span>
                <span className="label"><Translate msg="administration.prevention_units.abbreviation" /></span>
            </div>
        );
    }

    private transformFilterValuesToActiveFilters(
        transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
    ) {
        const { preventionUnitsGroupType } = this.props;

        return createGenericActiveFilters<IFilterValues, IColumnNames>({
            transformProps,
            translationKeyPrefix: `${TRANSLATION_PREFIX}.list.active_filter`,
            filters: {
                search: {
                    show: true,
                },
                type: {
                    show: true,
                    translationKeySuffixOverride: preventionUnitsGroupType,
                    multiple: {
                        enable: true,
                    },
                },
            },
        });
    }

    private isFetchArchiveExportNeeded() {
        return true;
    }

    private async doArchiveExportOfLatestYear(fetchProps: IFetchAllExportDataProps<IFilterValues>) {
        const { companyCode, overviewYear } = this.props;

        const document = await exportPreventionUnits({
            companyCode,
            year: overviewYear,
        });

        return document;
    }
}

const OverviewList = (props: IPublicProps & IRenderMasterContentProps<ListItem<IColumnNames>[]>) => (
    <TranslatorContext.Consumer>
        {({ translator }) => <OverviewListComp {...props} translator={translator} />}
    </TranslatorContext.Consumer>
);

class OverviewListComp extends Component<IPublicProps & TOverviewListProps> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);
    constructor(props: IPublicProps & TOverviewListProps) {
        super(props);

        this.sortListItemsAndAppendTotalRow = this.sortListItemsAndAppendTotalRow.bind(this);
        this.renderTypeHeader = this.renderTypeHeader.bind(this);
    }

    public render() {
        const {
            masterAsyncInfo,
            footer,
            onItemSelected,
            selectedItemId,
        } = this.props;

        this.columns.type.headerRender = this.renderTypeHeader;

        return (
            <List
                columns={this.columns}
                items={this.sortListItemsAndAppendTotalRow()}
                name={BASE_NAME}
                errorMessage={masterAsyncInfo.error &&
                    <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                footer={footer}
                selectedColumnName={selectedItemId as string}
                onColumnClicked={onItemSelected}
                getTableCellClasses={this.getTableCellClasses}
            />
        );
    }

    private renderTypeHeader() {
        const { preventionUnitsGroupType } = this.props;
        return <Translate msg={`administration.prevention_units.overview.list.columns.${preventionUnitsGroupType}`} />;
    }

    private getTableCellClasses(columnKey: keyof IColumnNames, listItem: ListItem<IColumnNames>) {
        if (listItem.id === LIST_ITEM_ID_TOTAL) {
            if (columnKey === PreventionUnitId.Planned) { return 'strong blue'; }
            if (columnKey === PreventionUnitId.Registered) { return 'strong green-dark'; }
            if (columnKey === PreventionUnitId.Invoiced) { return 'strong orange'; }
            return 'strong';
        }

        return null;
    }

    private sortListItemsAndAppendTotalRow() {
        const { masterData: clientSideFilteredListItems, translator } = this.props;

        const sortedListItems = sortListItems(clientSideFilteredListItems, INITIAL_SORT, this.columns['type']);

        if (sortedListItems.length <= 0) {
            return sortedListItems;
        }

        const accumulatorBase: Pick<
            IPreventionUnitNumbers, 'assigned' | 'invoiced' | 'planned' | 'registered' | 'remaining'
        > = {
            assigned: 0,
            invoiced: 0,
            planned: 0,
            registered: 0,
            remaining: 0,
        };

        const totalUnitsOfFilteredListItems = sortedListItems.reduce(
            (accummulator, item) => {
                return {
                    assigned: sumFloatsUpToSixDigitsAfterComma(
                        [accummulator.assigned, item.columns[PreventionUnitId.Assigned] as number]),
                    invoiced: sumFloatsUpToSixDigitsAfterComma(
                        [accummulator.invoiced, item.columns[PreventionUnitId.Invoiced] as number]),
                    planned: sumFloatsUpToSixDigitsAfterComma(
                        [accummulator.planned, item.columns[PreventionUnitId.Planned] as number]),
                    registered: sumFloatsUpToSixDigitsAfterComma(
                        [accummulator.registered, item.columns[PreventionUnitId.Registered] as number]),
                    remaining: sumFloatsUpToSixDigitsAfterComma(
                        [accummulator.remaining, item.columns[PreventionUnitId.Remaining] as number]),
                };
            },
            accumulatorBase,
        );

        sortedListItems.push({
            id: LIST_ITEM_ID_TOTAL,
            columns: {
                assigned: totalUnitsOfFilteredListItems.assigned,
                planned: totalUnitsOfFilteredListItems.planned,
                registered: totalUnitsOfFilteredListItems.registered,
                invoiced: totalUnitsOfFilteredListItems.invoiced,
                remaining: totalUnitsOfFilteredListItems.remaining,
                type: translator('administration.prevention_units.overview.list.total'),
            },
        });

        return sortedListItems;
    }
}

function mapPreventionUnitsToListItems(masterData: IPreventionUnits[]): ListItem<IColumnNames>[] {
    return masterData
        .map((item) => {
            return {
                id: item.id,
                columns: {
                    assigned: item.units.assigned,
                    planned: item.units.planned,
                    registered: item.units.registered,
                    invoiced: item.units.invoiced,
                    remaining: item.units.remaining,
                    type: item.description,
                },
            } as ListItem<IColumnNames>;
        });
}

function clientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const { listItems, filterValues, isFilterSet } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.type)) {
                return true;
            }

            const types = separateStringList(filterValues.type);
            return types.includes(listItem.columns.type.toString());
        });
}

function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator(`${TRANSLATION_PREFIX}.list.filter.search`)}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg={`${TRANSLATION_PREFIX}.list.filter.search`} />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContent(
    props: IPublicProps & IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        formRenderProps,
        masterData: allListItems,
        preventionUnitsGroupType,
    } = props;

    const possibleDocumentTypes = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'type',
        'type',
    );

    return (
        <div>
            <CheckboxesOrTypeaheadFilter
                filterName="type"
                labelTranslationKey={`${TRANSLATION_PREFIX}.list.filter.${preventionUnitsGroupType}`}
                possibleFilterItems={possibleDocumentTypes}
                actualFilterValue={formRenderProps.values.type}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'type',
                    newFilterValue,
                )}
            />
        </div>
    );
}

function createPreventionUnitLabel(config: IPreventionUnitConfigItem, listLabel?: boolean) {
    const infoIcon = (
        <Icon typeName="info" circle />
    );

    return (
        <span
            className={classNames({
                strong: !!listLabel,
                'has-tooltip': !!config.tooltipTranslationKey,
            })}
            style={listLabel && { color: config.legendColorOverride || config.color }}
        >
            {config.tooltipTranslationKey ? (
                <TooltipWithIcon
                    icon={infoIcon}
                    tooltipBubbleIcon={infoIcon}
                    iconSize="small"
                    typeName="info-bubble"
                    label={<Translate msg={config.labelTranslationKey} />}
                >
                    <Translate msg={config.tooltipTranslationKey} />
                </TooltipWithIcon>
            ) : (
                    <Translate msg={config.labelTranslationKey} />
                )}
        </span>
    );
}
